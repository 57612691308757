import { render, staticRenderFns } from "./intPatientList.vue?vue&type=template&id=4c7bbd97&scoped=true&"
import script from "./intPatientList.vue?vue&type=script&lang=js&"
export * from "./intPatientList.vue?vue&type=script&lang=js&"
import style0 from "./intPatientList.vue?vue&type=style&index=0&id=4c7bbd97&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7bbd97",
  null
  
)

export default component.exports